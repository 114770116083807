import { graphql } from "gatsby";
import React from "react";
import Layout from "../../components/layout/Layout";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation, Trans } from "gatsby-plugin-react-i18next";
import { StaticImage } from "gatsby-plugin-image";
import * as EasydiagStyles from "../../styles/easydiag.module.css";
import Seo from "../../components/seo";

export default function Easydiag({ data }) {
  const { t } = useTranslation();

  return (
    <Layout>
      <Seo description="Le diagnostic fonctionnel du moteur. L’analyse détaillée de combustion 5 gaz (O2, CO2, CO, HC, NOx) permet de vérifier si le moteur est à l'optimum de ses capacités fonctionnelles ou pas." />
      <Container fluid className={EasydiagStyles.Container}>
        <Row>
          <Col xs={12} lg={{ span: 6, offset: 1 }}>
            <h1 className={EasydiagStyles.Titre1}>
              <Trans i18nKey="ecosysteme.easydiag.savoirplus.h.1">
                <sup></sup>
              </Trans>
            </h1>

            <h2 className={EasydiagStyles.Titre2}>
              {t("ecosysteme.easydiag.savoirplus.h.2")}
            </h2>
            <p className={EasydiagStyles.Text}>
              <Trans i18nKey="ecosysteme.easydiag.savoirplus.p.1">
                a<sub>b</sub>
                <span>a</span>d<span>b</span>
              </Trans>
            </p>
          </Col>
          <Col
            xs={12}
            lg={{ span: 4, offset: 1 }}
            className={EasydiagStyles.ColImage}
          >
            <StaticImage
              className={EasydiagStyles.GatsbyImage}
              src="../../images/Inventeur.png"
              alt="Inventeur"
              placeholder="BLURRED"
              formats={["AUTO", "WEBP"]}
              layout="fullWidth"
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

// export page query
export const query = graphql`
  query Easydiag($language: String!) {
    projects: allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { lang: { eq: $language } } }
    ) {
      nodes {
        html
        frontmatter {
          lang
          title
          stack
          slug
          thumb {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        id
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
